import React from 'react';

const VideoTutorials = () => {
    return (
        <div className='video-tutorials'>
            <h1>Video Tutorials</h1>
            <strong>Coming Soon!</strong>
            {/* Content for Video Tutorials */}
        </div>
    );
};

export default VideoTutorials;
