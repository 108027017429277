import * as React from "react"

const ReportIcon1 = (props) => (
    <svg className="report-icon1"
        xmlns="http://www.w3.org/2000/svg"
        width={40}
        height={40}
        aria-hidden="true"
        viewBox="0 0 128 128"
        {...props}
    >
        <path
            fill="#82aec0"
            d="M118.4 112.29c-.54-2.88-2.62-18.81-2.62-18.81H12.22s-2.08 15.93-2.62 18.81C9.06 115.17 15.23 124 64 124s54.94-8.83 54.4-11.71z"
        />
        <path
            fill="#e0e0e0"
            d="M12.19 93.96c0-5.87 23.2-10.63 51.81-10.63s51.81 4.76 51.81 10.63-23.2 11.6-51.81 11.6-51.81-5.73-51.81-11.6z"
        />
        <path
            fill="#f44336"
            d="M97.78 16.32C94.94 7.27 85.48 4 63.99 4S33.05 7.27 30.21 16.32c-5.72 18.23-10.86 75.71-10.86 75.71s4.47 7.49 44.65 7.49 44.66-7.48 44.66-7.48-5.16-57.49-10.88-75.72z"
        />
        <path
            fill="#c62828"
            d="M19.34 92.14c0-4.6 19.99-8.33 44.66-8.33s44.66 3.73 44.66 8.33-19.99 9.09-44.66 9.09-44.66-4.49-44.66-9.09z"
            opacity={0.9}
        />
        <radialGradient
            id="a"
            cx={64}
            cy={52.903}
            r={43.973}
            gradientTransform="translate(.558 .558) scale(.9913)"
            gradientUnits="userSpaceOnUse"
        >
            <stop offset={0.572} stopColor="#ff6e40" />
            <stop offset={0.768} stopColor="#ff7046" stopOpacity={0.541} />
            <stop offset={1} stopColor="#ff7555" stopOpacity={0} />
        </radialGradient>
        <path
            fill="url(#a)"
            d="M72.59 35.73 94.42 13.2c.42-.37 1.04.1.8.6L78.98 39.86c-1.21 2.52-.14 4.15 2.66 4.08l20.65-.21c.56-.01.73.75.22.98l-18.84 7.26c-2.55 1.15-3.09 3.88-.91 5.62l23.49 16.94c.44.35.08 1.04-.46.89L79.06 64.35c-2.69-.76-3.92.73-3.37 3.47l8.62 28.89c.11.55-.61.85-.92.39L68.68 71.05c-1.57-2.31-3.47-2.75-4.8-.3L49.77 97.43c-.27.49-1.01.26-.96-.3l7.29-28.79c.28-2.78-1-3.36-3.6-2.34L22.25 81.51c-.52.2-.94-.45-.54-.84L47.09 59.6c2-1.95 1.91-4.08-.74-4.97l-21.18-7.22c-.53-.18-.43-.95.12-.99l20.84.4c2.79-.21 4.2-2.99 2.74-5.37L32.12 17.3c-.29-.48.28-1.01.73-.68l22.34 19.4c2.27 1.63 4.26 1.48 4.67-1.28l3.63-26c.08-.55.86-.59 1-.05l3.16 25.38c.7 2.71 2.84 3.51 4.94 1.66z"
        />
        <path
            fill="#ffd5ca"
            d="m65.85 34.06 9.36-16.89c.28-.48 1.02-.23.95.32l-2.92 18.45c-.34 2.77 1.2 3.98 3.82 3.02l18.33-6.16c.52-.19.93.47.52.85L81.55 45.91c-2.05 1.9-1.69 4.66.94 5.61l18.32 6.21c.52.19.41.96-.15.99l-19.52.39c-2.79.14-3.48 1.95-2.08 4.37l9.86 16.84c.28.48-.31 1-.75.66l-14.73-12.2c-2.23-1.68-4.16-1.5-4.64 1.25l-3.5 19.94c-.1.55-.88.57-1 .03l-3.64-19.77c-.62-2.72-2.02-2.86-4.16-1.07L41 82.19c-.43.36-1.04-.13-.78-.62l9.11-16.74c1.27-2.49.51-4.47-2.29-4.47l-19.66.25c-.56 0-.71-.76-.2-.98l17.16-6.61c2.57-1.09 3.02-4.17.88-5.97l-14.4-11.73c-.43-.36-.05-1.04.48-.88l18.17 5.44c2.67.82 4.51.04 4.02-2.71l-3.77-19.01c-.1-.55.63-.83.93-.37l10.01 16.26c1.51 2.35 3.8 2.43 5.19.01z"
        />
    </svg>
)
export default ReportIcon1
