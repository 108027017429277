import React from 'react';

const CommunityInsights = () => {
    return (
        <div className='community-insight'>
            <h1>Community Insights</h1>
            <strong>Coming Soon!</strong>
            {/* Content for Community Insights */}
        </div>
    );
};

export default CommunityInsights;